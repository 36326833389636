export const m = 
{
    lqlj: "確定受領です",
  sqinfo: "個人アカウントの機能を利用するには、ワレットライセンスを利用します。",
  sqbtn: "ワレットライセンスです",
  sqload: "ライセンスしています...",
  dltt: "あなたのアカウントに登録されます。",
  dlinfo: "個人アカウントの機能を利用するには、リンクウォレットをクリックします。",
  dlbtn: "リンクウォレットです",
  bdtt: "上級機関と結びつけることです",
  bdinfo: "推薦人のウォレットのアドレスを入力します。",
  bdbtn: "バインディング確認です",
  bdload: "バインディング中です...",
  bangsuc:"バインディング成功です",
  lqsg: "受領成功です",
  lqsb: "受領に失敗しました",
  day: "天です",
  shi:"時です",
  fen:"分けます",
  miao:"秒です",
  fzcg:"複製成功です",
  fzsb:"コピー失敗です",
  yhdj:"ユーザーレベルです",
  zwkf:"ヒント:まだ開いていません!！",
  mysj:"データがありません",
  wdyqlj:"私の招待リンクです",
  wdtjdz:"私のお勧めの住所です",
  fz:"複製します",
  home:"トップページです",
  qr:"確認します",
  ny:"お持ちです",
  wcqbqr:"ウォレット確認完了です",
  dqsl:"ライセンス数です",
  cyje:"参加金額です",
  qsrcyje:"参加金額を入力願います",
  zfsl:"支払い数量です",
  qbye:"財布の残高です",
  ljcy:"直ちに参加します",
  wdzc:"私の資産です",
  klqzc:"受領可能資産です",
  jrsy:"今日の収益です",
  ljsy:"累積収益",
  lq:"受け取ります",
  qrlq:"受領確認です",

  wddd:'私の註文です',
  yxz:"運行中です",
  ycj:"アウトです",
  cysj:"参加時間です",
  zfje:"支払い金額です",
  cjjd:"アウトノードです",

  wdsq:"私のコミュニティです",
  wddj:"私のランクです",
  sfjd:"ノードです",
  shi:"そうです",
  fou:"いいえ",
  kgdj:"鉱夫階級です",
  wu:"ありません",
  cjkg:"鉱夫初心者です",
  zjkg:"中級鉱夫です",
  gjkg:"高級鉱夫です",
  cjkgs:"スーパー鉱夫です",
  cskg:"鉱夫創世です",
  slzx:"算力センターです",
  grztslz:"個人の計算力です",
  grztrslz:"個人総投入計算力値です",
  ydztslz:"力を投げていました",
  erztslz:"二世は力の数値を計算します",
  xqztslz:"セルは力の値を投げます",
  sqztslz:"コミュニティは力の数値を計算します",
  wdfx:"私の分かち合いです",

  cycg:"参加できました",
  cysb:"失敗しました"


}
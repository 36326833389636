export const m = 
{ 
lqlj:"확인하세요.",
sqinfo:"개인 계정을 사용하려면 지갑 인증을 사용하십시오.",
sqbtn:"지갑 인증",
sqload:"라이선스로...",
dltt:"계정에 로그인",
dlinfo:"개인 계정의 기능을 사용하려면 지갑 링크를 클릭하십시오",
dlbtn:"링크 지갑",
bdtt:"상위 바인딩",
bdinfo:"추천인의 지갑 주소를 입력해주세요.",
bdbtn:"바인딩 확인",
bdload:"바인딩 중...",
bangsuc:"성공적으로 바인딩되었습니다.",
lqsg:"성공했습니다",
lqsb:"수신에 실패했습니다",
day:"하루",
shi:"시간",
fen:'나누다',
miao:"초",
fzcg:"성공적으로 복제했습니다.",
fzsb:"복제 실패",
yhdj:"사용자 레벨",
zwkf:"경고:아직 개방하지 않았습니다!",
mysj:"데이터가 없어",
wdyqlj:"내 초대장 링크",
wdtjdz:"내 추천 주소",
fz:"복제",
home:"홈",
qr:"확인",
ny:"있잖아요",
wcqbqr:"지갑 확인 완료",
dqsl:"공인 수량",
cyje:"참여 금액",
qsrcyje:"참여 금액을 입력해주세요.",
zfsl:"지불 수량",
qbye:"지갑 잔고",
ljcy:"즉시 참여하세요.",
wdzc:"나의 자산",
klqzc:"지급가능한 자산",
jrsy:"오늘의 수익",
ljsy:"누적 수익입니다.",
lq:"받아요.",
qrlq:"수령 확인",

wddd:"나의 주문서",
yxz:"실행 중입니다",
ycj:"나갔습니다.",
cysj:"참여의 시간",
zfje:"지불 금액",
cjjd:"아웃노드",

wdsq:"나의 커뮤니티",
wddj:"나의 클래스",
sfjd:"노드 여부",
shi:"그렇다.",
fou:"아니요",
kgdj:"광부 계급",
wu:'아니요',
cjkg:"초급 광부",
zjkg:"중간 광부",
gjkg:"고급 광부",
cjkgs:"슈퍼 광부",
cskg:"창조의 광부",
slzx:"산력센터",
grztslz:"개인이 힘을 투자하고 있습니다.",
grztrslz:"개인총투입계산력치",
ydztslz:"한 세대가 힘의 가치를 투자하고 있습니다.",
erztslz:"제2세대 투자 힘",
xqztslz:"소구역 투입 힘",
sqztslz:"지역사회가 생산력을 투자하고 있습니다",
wdfx:"나의 공유",
cycg:"성공적으로 참여했습니다.",
cysb:"참여 실패"

}